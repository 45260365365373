<template>
  <div class="gradBackground">
    <v-container class="ma-0">

      <v-row class="white--text mt-2 ml-3">
        <ShadowedTitle title="Programación" />
      </v-row>

      <v-row v-if="!isLoading">
        <v-col v-if="nextEvents.length > 0 || pastEvents.length > 0">

          <v-timeline dense clipped >

            <!-- Next events -->
            <v-timeline-item color="green" icon="mdi-check">
              <v-chip
                color="green"
                text-color="white"
                label
                class="ma-1"
              >
                Próximos eventos
              </v-chip>
            </v-timeline-item>

            <TimelineProgramItem v-for="(program, idx) in nextEvents" :key="idx" :program="program" status="next" />

            <!-- Past events -->
            <v-timeline-item color="grey darken-1" icon="mdi-clock-outline">
              <v-chip
                color="grey darken-1"
                text-color="white"
                label
                class="ma-1"
              >
                Eventos pasados
              </v-chip>
            </v-timeline-item>

            <TimelineProgramItem v-for="(program, idx) in pastEvents" :key="`${idx}-past`" :program="program" status="past" />
          
          </v-timeline>

        </v-col>

        <v-col v-else sm="12">
          <v-alert type="info" elevation="5" class="my-5" >Lo sentimos, pero la programación no está disponible por el momento</v-alert>
        </v-col>

      </v-row>

      <LoadingIndicator v-else />

    </v-container>
  </div>
</template>

<script>
import TimelineProgramItem from '@/components/TimelineProgramItem.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import ShadowedTitle from "@/components/ShadowedTitle.vue"

export default {
  name: "Programming",
  components: {
    ShadowedTitle,
    TimelineProgramItem,
    LoadingIndicator,
  },
  data:() => {
    return {
      //state
      isLoading: true,
      //definitions
      nextEvents: [],
      pastEvents: [],
    }
  },
  methods: {
    async loadProgram() {
      try {

        this.isLoading = true

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/events`)

        const events = response.data.data.map(event => {

          const endDate = (event.endDate) ? new Date(event.endDate.replace('Z', '')) : null
          return { ...event, date: new Date(event.date.replace('Z', '')), endDate }
        })


        const now = new Date()

        this.nextEvents = events.filter(event => event.date >= now)
        this.pastEvents = events.filter(event => event.date < now)

      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    }
  },
  mounted() {
    this.loadProgram()
  }
}
</script>

<style lang="scss" scoped>
.gradBackground {
  height: 100%;
  background: linear-gradient(to left, #8e9eab 0%, #dfe6e7 100%);
}
</style>