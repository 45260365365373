<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="show"
    width="500"
  >

    <v-card>
      <v-card-title class="blue darken-2 white--text mb-4">
        <v-icon left dark>mdi-information-off-outline</v-icon>
        No hay más información
      </v-card-title>

      <v-card-text>
        Lo sentimos, por el momento aún no existe más información del evento seleccionado
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click.stop="closeDialog()" >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NoAvailableArticle",
  props:{
    value: Boolean
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value)  {
         this.$emit('input', value)
      }
    },
  },
  methods: {
    closeDialog() {
      this.show = false
    }
  }
}
</script>