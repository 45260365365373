<template>
  <v-timeline-item :color="(status === 'next') ? 'green' : 'grey'" small right>

    <v-card elevation="11" @click="launchArticle()" max-width="800px">

      <v-container>

        <v-row>

          <v-col v-if="program.article" md="3" sm="12">
            <v-avatar size="100%" tile>
              <v-img src="https://cdn.vuetifyjs.com/images/cards/foster.jpg" max-width="160px"></v-img>
            </v-avatar>
          </v-col>

          <v-col :md="(program.article) ? '9' : '12'" sm="12">

            <v-card-title class="pt-2 pb-5 min-line-height" :class="(status == 'next') ? 'purple lighten-2' : 'grey'">
              <h5 class="white--text text-wrap">
                {{program.title}}
              </h5>
            </v-card-title>

            <v-card-subtitle class="white--text pb-2 mb-3" :class="(status == 'next') ? 'purple lighten-2' : 'grey'">
              <div v-if="program.date">
                {{formattedDate}} {{formattedEndDate}}  
              </div>
              <div v-else>
                Fecha por confirmar
              </div>
            </v-card-subtitle>

            <v-container class="py-0 pl-0">
              <v-row>
                <v-col>

                  <v-chip-group column>

                    <v-chip class="white--text py-2" :color="(program.isOnline) ? 'blue' : 'green'" label small outlined>
                      <v-icon left small>
                        {{(program.isOnline) ? 'mdi-cast' : 'mdi-map-marker'}}
                      </v-icon>
                      <strong> {{(program.isOnline) ? 'Modalidad Online' : 'Modalidad Presencial'}} </strong>
                    </v-chip>

                    <v-chip v-if="program.isOnline && program.streamLocation" class="white--text py-2" color="purple" label small outlined>
                      <v-icon left small>mdi-video</v-icon>
                      Transmisión por {{program.streamLocation}}
                    </v-chip>

                  </v-chip-group>

                </v-col>
              </v-row>
            </v-container>

          </v-col>

        </v-row>

      </v-container>

    </v-card>

    <NoAvailableArticle v-model="isOpenBadArticle" />

  </v-timeline-item>
</template>

<script>

import NoAvailableArticle from "@/components/NoAvailableArticle"
import {getLongMonthName, datesAreOnSameDay} from "@/libs/dateHelpers"

export default {
  name: "TimelineProgramItem",
  components: {
    NoAvailableArticle
  },
  props: {
    program: {
      type: Object,
      required: true
    },
    status: {
      type: String,
      default: "next"
    }
  },
  computed: {
    formattedDate: function() {
      if(!(this.program.date instanceof Date)) return ''
      return `${this.program.date.getDate()} de ${getLongMonthName(this.program.date)} - ${this.program.date.getHours()}:${(this.program.date.getMinutes() < 10) ? '0' : ''}${this.program.date.getMinutes()} hrs`
    },
    formattedEndDate: function() {
      if(!(this.program.date instanceof Date) || !(this.program.endDate instanceof Date)) return ''

      if(datesAreOnSameDay(this.program.date, this.program.endDate)) {
        return ` a ${this.program.endDate.getHours()}:${(this.program.endDate.getMinutes() < 10) ? '0' : ''}${this.program.endDate.getMinutes()} hrs`
      } else {
        return ` hasta el día ${this.program.endDate.getDate()} de ${getLongMonthName(this.program.endDate)} - ${this.program.endDate.getHours()}:${(this.program.endDate.getMinutes() < 10) ? '0' : ''}${this.program.endDate.getMinutes()} hrs`
      }

    }
  },
  data:() => {
    return {
      //state
      isOpenBadArticle: false,
    }
  },
  methods: {
    launchArticle() {
      if(this.program.article != null) {
        this.$router.push(`/articulos/${this.program.article}`)
      } else {
        this.isOpenBadArticle = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.min-line-height {
  line-height: 1.1em;
  word-break: break-word;
}

</style>
